$(document).on('turbolinks:load', function(){

  //price range
  var $price_range = $("#price_range");
  var $inputFrom = $("#price_input_from");
  var $inputTo = $("#price_input_to");
  var price_instance;
  var min = $('#price').data('min_price');
  var max = $('#price').data('max_price');
  var from = $('#price_input_from').data('price-input-from');
  var to = $('#price_input_to').data('price-input-to');

  let irs = $('.irs--round')
  if (irs){
    irs.remove()
  }

  $price_range.ionRangeSlider({
      skin: "round",
      type: "double",
      min: min,
      max: max,
      from: from,
      to: to,
      onStart: updateInputs,
      onChange: updateInputs,
      onFinish: finishUpdate
  });


  price_instance = $price_range.data("ionRangeSlider");

  function finishUpdate(data) {
    updateInputs(data)
    changePriceURLSearchParams(data)
  }

  function changePriceURLSearchParams(data){
    urlParams = new URLSearchParams(window.location.search);

    urlParams.delete('page');
    newURL = location.href.split("?")[0]

    urlParams.set('price_from', data['from']);
    urlParams.set('price_to', data['to']);

    window.history.replaceState(null, null, newURL + '?' + urlParams.toString());

    $.get(newURL + '?' + urlParams.toString(), {}, null, "script");
  }

  function updateInputs (data) {
      from = data.from;
      to = data.to;

      $inputFrom.prop("value", from);
      $inputTo.prop("value", to);
  }

  $inputFrom.on("change", function () {
      var val = $(this).prop("value");

      // validate
      if (val < min) {
          val = min;
      } else if (val > to) {
          val = to;
      }

      price_instance.update({
          from: val
      });

      $(this).prop("value", val);

      var price_data = {}

      price_data['from'] = price_instance['options']['from']
      price_data['to'] = price_instance['options']['to']

      changePriceURLSearchParams(price_data);
  });

  $inputTo.on("change", function () {
      var val = $(this).prop("value");

      // validate
      if (val < from) {
          val = from;
      } else if (val > max) {
          val = max;
      }

      price_instance.update({
          to: val
      });


      $(this).prop("value", val);

      var price_data = {}
      price_data['from'] = price_instance['options']['from']
      price_data['to'] = price_instance['options']['to']

      changePriceURLSearchParams(price_data);
  });


  //amount range

  var $amount_range = $("#amount_range");
  var $amount_input = $("#amount_input");
  var amount_instance;
  var amount_min = 0;
  var amount_max = $('#amount').data('max_amount');
  var amount_from = $('#amount_input').data('amount_input');

  $amount_range.ionRangeSlider({
      skin: "round",
      type: "single",
      min: amount_min,
      max: amount_max,
      from: amount_from,
      onStart: function(data) {
          $amount_input.prop("value", data.from);
      },
      onChange: function(data) {
          $amount_input.prop("value", data.from);
      },
      onFinish: function(data) {
          $amount_input.prop("value", data.from);
          changeAmountURLSearchParams(data);
      }
  });

  amount_instance = $amount_range.data("ionRangeSlider");



  $amount_input.on("change", function() {
      var val = $(this).prop("value");

      // validate
      if (val < amount_min) {
          val = amount_min;
      } else if (val > amount_max) {
          val = amount_max;
      }

      amount_instance.update({
          from: val
      });
      var amount_data = {}
      amount_data['from'] = val

      changeAmountURLSearchParams(amount_data);
  });

  function changeAmountURLSearchParams(data){
    urlParams = new URLSearchParams(window.location.search);

    urlParams.delete('page');
    newURL = location.href.split("?")[0]

    urlParams.set('amount', data['from']);

    window.history.replaceState(null, null, newURL + '?' + urlParams.toString());

    $.get(newURL + '?' + urlParams.toString(), {}, null, "script");
  }

})
