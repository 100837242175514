$(document).on('turbolinks:load', function(){

  window.jivo_onLoadCallback = function() {
    return $(function() {
      var params;
      params = JSON.stringify({
        roistat: Cookies.get('roistat_visit') || ''
      });
      return typeof jivo_api !== "undefined" && jivo_api !== null ? jivo_api.setUserToken(params) : void 0;
    });
  };

})
