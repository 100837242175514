$(document).on('turbolinks:load', function(){
  $('.catalog-nav').on('click', '.bar', function(e) {
    e.preventDefault()
    $('.catalog-nav').removeClass('show')
    $('body').removeClass('overflow-hidden')
    $('.header__catalog').removeClass('active')
  })
  $('.js-btn-catalog').on('click', function(e) {
    e.preventDefault()
    if ($('.catalog-nav').hasClass('show')){
      $('.catalog-nav').removeClass('show')
      $('body').removeClass('overflow-hidden')
      $('.header__catalog').removeClass('active')
    }else{
      $('.catalog-nav').addClass('show')
      $('body').addClass('overflow-hidden')
      $('.header__catalog').addClass('active')
    }
  })

  $('.catalog-link-item').mouseover(function (){
    if (window.matchMedia("(max-width: 699px)").matches) {
    return true
    }
    let dataId = $(this).data('id')
    $('.catalog-link-item').removeClass('active')
    $(this).addClass('active')
    $('.selected-category').find('.category').removeClass('active')
    $('.catalog-nav__selected-category').addClass('active')
    $('.category-'+dataId).addClass('active')
  })

  $('.catalog-link-item').on('click', function(){
    let dataId = $(this).data('id')
    $('.catalog-link-item').removeClass('active')
    $(this).addClass('active')
    $('.selected-category').find('.category').removeClass('active')
    $('.catalog-nav__selected-category').addClass('active')
    $('.category-'+dataId).addClass('active')
  })

  $('.exit-children-category').on('click', function(e) {
    $('.catalog-nav__selected-category').removeClass('active')
  })

  let h_nav = $('.header__top').outerHeight();
  $(window).scroll(function(){
    if($(this).scrollTop() > h_nav){
        $('.header__top').addClass('fixed');
    }
    else if ($(this).scrollTop() < h_nav){
        $('.header__top').removeClass('fixed');
    }
  });


  $('.header__menu-mobile').on('click', function(e) {
    e.preventDefault()
    if ($('.header__menu-mobile').hasClass('active')){
      $('.header__menu-mobile').removeClass('active')
      $('body').removeClass('overflow-hidden')
      $('.header__mobile-nav').removeClass('show')
    }else{
      $('.header__menu-mobile').addClass('active')
      $('body').addClass('overflow-hidden')
      $('.header__mobile-nav').addClass('show')
    }
  })

  $('#sidebar-show').on('click', function(e) {
    e.preventDefault()
    $('.catalog__sidebar').addClass('active')
  })

  $('.sidebar__title').on('click', function(e) {
    e.preventDefault()
    $('.catalog__sidebar').removeClass('active')
  })

})
