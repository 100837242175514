$(document).on('turbolinks:load', function(){
  let input = document.querySelector('.search-client-new-order');

  if (input){
    let select = document.querySelectorAll('.select-clients');
    let options = Array.from(select[0].options);

    input.addEventListener('change', filterOptions);
    input.addEventListener('keyup', filterOptions);



    function findMatches (search, options) {
      return options.filter(option => {
        let regex = new RegExp(search, 'gi');
        return option.text.match(regex);
      });
    }

    function filterOptions () {
      options.forEach(option => {
        option.remove();
        option.selected = false;
      });
      let matchArray = findMatches(this.value, options);
      select[0].append(...matchArray);
    }}
})
