$(document).on('turbolinks:load', function(){
  $("#main-banner").owlCarousel({
      loop:true,
      dots: false,
      lazyLoad: true,
      lazyLoadEager: 1,
      autoplay: true,
      autoplayTimeout: 7000,
      nav: true,
      responsive:{
        0:{
            items: 1
        }
      }
  });

  $("#similar-products").owlCarousel({
      loop:true,
      dots: true,
      lazyLoad: true,
      touchDrag: true,
      autoplat: false,
      responsive:{
        0:{
            items:1.5
        },
        765:{
            items:2
        },
        1050:{
            items:4
        }
      }
  });

  $("#product-page-slider").owlCarousel({
      loop:true,
      dots: false,
      lazyLoad: true,
      nav: true,
      responsive:{
        0:{
            items:2
        },
        1050:{
            items:3
        }
      }
  });

  $('#product-page-slider').on('click', 'img', function() {
    let src = $(this).prop('src');
    $('#big_img_product').html('<img src="' + src + '">');
  })
  $("#main-bottom-slider").owlCarousel({
      loop:true,
      dots: false,
      responsive:{
        0:{
            items:2
        },
        765:{
            items:5
        },
        780:{
            items:6
        }
      }
  });

  $("#product-image-slider").owlCarousel({
      loop:true,
      dots: true,
      lazyLoad: true,
      touchDrag: true,
      autoplat: false,
      responsive:{
        0:{
            items: 1
        }
      }
  });

  $("#tabs-new").owlCarousel({
    loop:true,
    dots: true,
    lazyLoad: true,
    touchDrag: true,
    autoplat: false,
    items: 1.5,
  });

  $("#tabs-hit").owlCarousel({
    loop:true,
    dots: true,
    lazyLoad: true,
    touchDrag: true,
    autoplat: false,
    items: 1.5,
  });

  $("#tabs-benefits").owlCarousel({
      loop:true,
      dots: true,
      lazyLoad: true,
      touchDrag: true,
      autoplat: false,
      items: 1.5,
  });

  $('.product-cards-list').find(".owl-carousel").each(function() {
    var $this = $(this);
    $this.owlCarousel({
      autoplat: false,
      loop:true,
      touchDrag: true,
      items : 1,
      nav: true,
      dots: false,
      lazyLoad: true
    });
    // Custom Navigation Events
    $this.parent().find(".next").click(function(){
      $this.trigger('owl.next');
    });
    $this.parent().find(".prev").click(function(){
      $this.trigger('owl.prev');
    });
  });
})
