// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
require('jquery')
import "channels"
import './menu'
import 'stylesheets/variables'
import './libs/owl-carousel-min'
import './libs/modal'
import './libs/jquery-3.3.1.maskedinput.min.js'
window.Cookies = require('./libs/js.cookie')
import './owl-carousel'
import './tabs'
import './height-block'
import './product'
import './filter'
import './cart'
import './manager/form_inline'
import './utm'
import './integrations'
import './libs/ion.rangeSlider'
import './libs/ion.rangeSlider.min'
import './range'
import './product_filters'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
