$(document).on('turbolinks:load', function(){
  window.UtmData = {
    item_id: '_gifts_utm_data',
    storeData: function() {
      var parameters;
      parameters = this.filterUtmParameters(this.decodeUriQuery(window.location.search));
      if (jQuery.isEmptyObject(parameters)) {
        return false;
      }
      try {
        localStorage.setItem(this.item_id, JSON.stringify(parameters));
        return true;
      } catch (_error) {
        return false;
      }
    },
    listData: function() {
      try {
        return JSON.parse(localStorage.getItem(this.item_id));
      } catch (_error) {
        return {};
      }
    },
    listAsFormData: function() {
      var key, result, value, _ref;
      result = [];
      _ref = this.listData();
      for (key in _ref) {
        value = _ref[key];
        result.push({
          name: "utm[" + key + "]",
          value: value
        });
      }
      return result;
    },
    filterUtmParameters: function(parameters) {
      var key, result, utmKeys, value;
      utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
      result = {};
      for (key in parameters) {
        value = parameters[key];
        if (utmKeys.indexOf(key) !== -1) {
          result[key] = parameters[key];
        }
      }
      return result;
    },
    decodeUriQuery: function(search) {
      var d, m, params, query, regex;
      d = function(str) {
        return decodeURIComponent(str.replace(/\+/g, ' '));
      };
      query = search.substring(1);
      regex = /(.*?)=([^\&]*)&?/g;
      params = {};
      while (m = regex.exec(query)) {
        params[d(m[1])] = d(m[2]);
      }
      return params;
    }
  };

  jQuery(function() {
    return window.UtmData.storeData();
  });

})
