$(document).on('turbolinks:load', function(){
  $('form').on('input', '#cart_item_quantity', function(){
    $.ajax({
      type: "PATCH",
      url: $(this).closest('form').attr('action'), //sumbits it to the given url of the form
      data: $(this).closest('form').serialize(),
    })
  })

  $("#cart_item_quantity").keypress(function(event) {
      if (!event.charCode) return true;
      ch = String.fromCharCode(event.charCode);
      return (/[\d]/.test(ch));
  });
  $('#phone').mask('+7 (999) 999-9999')

  $('.dropdown-content').on('mouseleave', function(){
    $(this).hide()
  })

  $('.dropdown').on('click', '.btn-branding', function(e) {
    $(this).closest('.dropdown').find('.dropdown-content').toggle()
  })

})
