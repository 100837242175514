$(document).on('turbolinks:load', function(){
  $(window).on('popstate', function (e) {
    if(window.history.state === null){
    window.location = document.location.href
  }
  });

  $('.catalog__active-filter').on('click', '#delete-price-filter', function(e) {
    urlParams = new URLSearchParams(window.location.search);
    newURL = location.href.split("?")[0]

    urlParams.delete('page');
    urlParams.delete('price_to');
    urlParams.delete('price_from');

    window.history.replaceState(null, null, newURL + '?' + urlParams.toString());
    $.get(newURL + '?' + urlParams.toString(), {}, null, "script");
  })

  $('.catalog__active-filter').on('click', '#delete-amount-filter', function(e) {
    urlParams = new URLSearchParams(window.location.search);
    newURL = location.href.split("?")[0]

    urlParams.delete('page');
    urlParams.delete('amount');

    window.history.replaceState(null, null, newURL + '?' + urlParams.toString());
    $.get(newURL + '?' + urlParams.toString(), {}, null, "script");
  })

  $('.catalog__active-filter').on('click', '#delete-colors-filter', function(e) {
    urlParams = new URLSearchParams(window.location.search);
    data = $(this).data('color')
    newURL = location.href.split("?")[0]

    urlParams.delete('page');
    colorParams = urlParams.get('colors')

    urlParams.set('colors', updateData(data.toString(), colorParams))
    if(urlParams.get('colors') == ''){ urlParams.delete('colors'); }

    window.history.replaceState(null, null, newURL + '?' + urlParams.toString());
    $.get(newURL + '?' + urlParams.toString(), {}, null, "script");
  })

  $('.colors-filter-wrapper').on('change', '.custom-checkbox', function(e) {

    urlParams = new URLSearchParams(window.location.search);
    data = $(this).closest('.check-box-filter').data('color_filter')
    urlParams.delete('page');
    newURL = location.href.split("?")[0]

    colorParams = urlParams.get('colors')

    if (colorParams == null){
      urlParams.set('colors', data)
    } else {
      urlParams.set('colors', updateData(data.toString(), colorParams))
    }

    if(urlParams.get('colors') == ''){ urlParams.delete('colors'); }

    window.history.replaceState(null, null, newURL + '?' + urlParams.toString());
    $.get(newURL + '?' + urlParams.toString(), {}, null, "script");
  })

  function updateData(data, colorParams){
    arr = colorParams.split(',')
    index = arr.indexOf(data)

    if( index == -1 ){
      arr.push(data)
    } else {
      arr.splice(index, 1);
    }

    return arr.join(',')
  }
})
