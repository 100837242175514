$(document).on('turbolinks:load', function(){

  $('.product-cards-list').on('mousemove', '.product-prev__group-prev', function(){
    $(this).css({'maxHeight':'none'})
    $(this).find('.group-prev-count').hide()
  })
  $('.product-cards-list').on('mouseleave', '.product-prev', function(){
    $(this).find('.group-prev-count').show()
    $(this).find('.product-prev__group-prev').css({'maxHeight':productPrevHeight()})
    $(this).find('.product-prev__add-cart-form').css({'display':'none'})
  })

  function productPrevHeight(){
    if (window.matchMedia("(max-width: 699px)").matches) {
    return '30px'
    }
    if (window.matchMedia("(max-width: 1439px)").matches) {
    return '40px'
    }
    return '50px'
  }

  $('.product-cards-list').on('click', '.product-prev__cart', function(e) {
    e.preventDefault()
    $(this).closest('.product-prev').find('.product-prev__add-cart-form').css({'display':'flex'})
  })

  $('body').on('click', '.product-poppup__button-close', function(e) {
    e.preventDefault()
    modal.destroy()
  })
});
