$(document).on('turbolinks:load', function(){
  $('.advantages').height(advantagesBannerHeight())

  $(window).resize(function(){
    $('.advantages').height(advantagesBannerHeight())
  });

  function advantagesBannerHeight(){
    if (window.matchMedia("(max-width: 699px)").matches) {
    return $('.main-banner').width() * 2.01
    }
    if (window.matchMedia("(min-width: 1051px)").matches) {
    return $('.main-banner').width() * 0.458
    }
    return $('.main-banner').width() * 0.875
  }

  $('.catalog-block-footer').on('click', function(e) {
    let hideItems = $(this).closest('.catalog-block-inner').find('.catalog-block-subitem')
    $(this).addClass('item-hide')
    hideItems.removeClass('item-hide')
  })
});
